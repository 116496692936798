
// import Pagination from 'rc-pagination'
import React, { Component, useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, CardHeader } from 'shards-react'
import "../../assets/pagination.css";
import { _http } from '../../interceptor';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../../assets/default.css";
import Cookies from 'universal-cookie';

import 'react-loading-skeleton/dist/skeleton.css'
import "../../assets/default.css";
// import {Pagination} from '@shopify/polaris';

const RefferedStores = () => {
  const cookies = new Cookies();
  const [Tableloading, setTableloading] = useState(true)
  const [storeList, setstoreList] = useState([])
  const pageLength = 10;
  const [pageOffset, setpageOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [showLimit, setShoWlimit] = useState(10);
  useEffect(() => {
    let uid = cookies.get('userId')
    let affiliateToken = cookies.get('apiToken')
    _http.post('/dashboard', { uid, affiliateToken, page, showLimit }).then(res => {
      if (res.data.Status == '200') {
        setstoreList(res.data.dashboardData)
        setTableloading(false)
      }
    }).catch(err => {
      setTableloading(false)
      // console.log(err)
    })
    // },[pageOffset])
  }, [page])


  return (<>
    <div className="table-res">
      <table className="table mb-4" style={{ minWidth: "800px", overflow: "scroll" }}>
        <thead className="bg-light">
          <tr>
            <th scope="col" className="border-0">
              Store name
            </th>
            <th scope="col" className="border-0">
              Store commision
            </th>
            <th scope="col" className="border-0">
              Store Plan
            </th>
            <th scope="col" className="border-0">
              Registered Date
            </th>
            <th scope="col" className="border-0">
              Current commision
            </th>
            <th scope="col" className="border-0">
              Total commision
            </th>

          </tr>
        </thead>
        <tbody>
          {Tableloading && <tr>
            <td><Skeleton animation="wave" count={2} /></td>
            <td><Skeleton count={2} /></td>
            {/* <td><Skeleton count={2} /></td> */}
            <td><Skeleton count={2} /></td>
            <td><Skeleton count={2} /></td>
            <td><Skeleton count={2} /></td>
            <td><Skeleton count={2} /></td>
          </tr>}
          {storeList.map((e, i) => {
            let date = e.created_at;
            let dat = date.split('T')[0];
            // url={/e.myShopifyDomain}
            // let commisions=e.totalCommision
            // let comision=commisions.toFixed(2)
            // `/${e.myShopifyDomain}`
            return (<tr key={i}>
              <td> <a href={`https://${e.myShopifyDomain}`} target="_blank"
             
              >{e.myShopifyDomain}</a></td>
              <td>
                {/* {e.storeCommision.toFixed(2)} */}
                {e.storeCommission}
              </td>
              <td>{e.planName}</td>
              {/* <td>{e.views}</td> */}
              <td>{dat}</td>
              <td>{e.CurrentCommission}</td>
              <td>{e.totalCommision}</td>

            </tr>)
          })}
        </tbody>
      </table>
    </div>

    <div style={{ display: "flex", justifyContent: "center" }}>
      {/* <Pagination
                    label={page}
                    hasPrevious
                    onPrevious={() => {
                      setPage(page == 1 ? page : page - 1);
                    }}
                    hasNext
                    onNext={() => {
                      setPage(page + 1);
                    }}
                  /> */}

      <ButtonGroup style={{ gap: "10px" }}>
        <Button
          // label={page}
          outline theme="secondary" onClick={() => {
            setPage(page == 1 ? page : page - 1)

          }}>{'<'}</Button>
        {/* {page} */}

        <Button outline theme="secondary" onClick={() => {
          setPage(page + 1)

        }}>{'>'}</Button>
      </ButtonGroup>

    </div>
    <br />

  </>)
}

export default RefferedStores