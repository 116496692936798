import axios from 'axios';
import React, { Component, useCallback, useState } from 'react'
import { useHistory, useRouteMatch,Router, Route } from 'react-router';
import {
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    FormInput,
    Button,
    Card,
    CardBody,
    CardHeader,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react";
import { _http } from '../interceptor';
import { notification } from '../utils/notifications';
import Cookies from 'universal-cookie';
const Login = () => {
    document.title="Reviewbit | Affiliate Registration"
    const cookies = new Cookies();
    var Router = useHistory();
    const [firstname,setfirstname] = useState('')
    const [lastname,setlastname] = useState('')
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('')
    const [rePassword,setrePassword] = useState('');
    const [partnerAcc,setpartnerAcc] = useState('')
    const [passwordType, setpasswordType] = useState("password")
    const [emailType, setemailType] = useState("email")

    const handleFirstname = useCallback((value)=>{
      setfirstname(value)
    })
    const handleLastname = useCallback((value)=>{
      setlastname(value)
    })
    const handleUsername = useCallback((value) => {
        setusername(value)
    })
    const handlePassword = useCallback((value) => {
        setpassword(value)
    })
    const handleRePassword = useCallback((value) =>{
      setrePassword(value)
      
      
    })

    const handlePartner = useCallback((value)=>{
      setpartnerAcc(value)
    })
    const Loginnow=()=>{
        Router.push('/login')
    }
  
    function Register(){
        if(firstname!=''&&lastname!=''&&username!=''&&password!=''){
            let body = {
                "firstName": firstname,
                "lastName": lastname,
                // "partnerAccNo": partnerAcc,
                "email": username,
                "password": password,
                "isPartner" : true
            }
            //  console.log(body);
            _http.post('/signup',body).then(res=>{
             
                 if(res.data.Status=="200"){
                    console.log(res);
                    cookies.set('userId',res.data.UserDetails._id);
                    cookies.set('apiToken',res.data.UserDetails.apiToken);
                    cookies.set('useremail',res.data.UserDetails.email);
                    notification(res.data.Message,'success','Signup');
                    Router.push('/dashboard')

                 }
                    //  cookies.set('userId',res.data.userDetails._id);
                    // cookies.set('apiToken',res.data.userDetails.apiToken);
                    // notification(res.data.Message,'success','Signup');
               

            }).catch(err=>{
                if(err){
            //    console.log(err)
                     notification(err.data.Message,'danger','Error')
                }
            })
        }
       else{
        notification("Enter data",'danger','Error')   
       }
        
    }

    return (<div style={{ marginTop: "5%" }}>
        <Col sm="12" lg={{ size: 4, offset: 4 }}>
            <Card >
                <CardHeader className="border-bottom">
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img src="reviewbit-logo.png" style={{width:"100px"}}></img>
                    </div>
                    <h6 style={{ display: 'flex', justifyContent: "center",margin:"0" ,fontSize: "20px",
                    fontWeight: "bold"}} >Sign up </h6>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row form>
                            <Col md="12" className="form-group">
                                <FormInput
                                //   invalid={firstname==""?true:false}
                              
                                //  valid={(firstname!='')?true:false}
                               
                                    value={firstname}
                                    placeholder="Enter your first name"
                                    required
                                    autoComplete="off"
                                    // valid
                                    onChange={
                                        ($event) => {
                                          handleFirstname($event.target.value)
                                        }
                                    }
                                />
                                {/* {firstname?"": <FormFeedback>firstname required</FormFeedback>} */}
                            </Col>
                            <Col md="12" className="form-group">
                                <FormInput
                                    // invalid={lastname==""?true:false}
                                    // valid={(lastname!='')?true:false}
                                    value={lastname}
                                    placeholder="Enter your last name"
                                    required
                                    autoComplete="off"
                                    // valid
                                    onChange={
                                        ($event) => {
                                          handleLastname($event.target.value)
                                        }
                                    }
                                />
                            </Col>
                            <Col md="12" className="form-group">
                                <FormInput
                                //  invalid={username==""?true:false}
                                //  valid={(lastname!='')?true:false}
                                    value={username}
                                    placeholder="Enter your email"
                                    required
                                    autoComplete="off"
                                    // valid
                                    // type="email"
                                    type={emailType}
                                    onChange={
                                        ($event) => {
                                            handleUsername($event.target.value)
                                        }
                                    }
                                />
                                {/* emailType, setemailType */}
                            </Col>
                            {/* <Col md="12" className="form-group">
                                <FormInput
                                    value={partnerAcc}
                                    placeholder="Enter your partner account no"
                                    required
                                    // valid
                                    type="number"
                                    onChange={
                                        ($event) => {
                                            handlePartner($event.target.value)
                                        }
                                    }
                                />
                            </Col> */}
                            <Col md="12" className="form-group">
                                <InputGroup>
                                    <FormInput
                                    valid={(password!='')?true:false}
                                    //  invalid={password==""?true:false}
                                    //  valid={password}
                                        value={password}
                                        placeholder="Enter password"
                                        required
                                        autoComplete="off"
                                        type={passwordType}
                                        // valid
                                        onChange={($event) => {
                                            // console.log($event.target.value)
                                            handlePassword($event.target.value)
                                        }}
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText>
                                            <i class="fas fa-eye"  style={passwordType!=='password'?{color:'#007bff'}:{ cursor: "pointer" }} onClick={() => {
                                                if (passwordType == "password") {
                                                    setpasswordType("text")
                                                }
                                                else {
                                                    setpasswordType("password")
                                                }
                                            }}>
                                            </i>
                                            </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                            <Col md="12" className="form-group">
                                <InputGroup>
                                    <FormInput
                                        value={rePassword}
                                        placeholder="Re-enter password"
                                        required
                                        autoComplete="off"
                                        type={passwordType}
                                        valid={(password==rePassword&&rePassword!='')?true:false}
                                        invalid={(rePassword==''||password==rePassword)?false:true}
                                        onChange={($event) => {
                                            // console.log($event.target.value)
                                            handleRePassword($event.target.value)
                                        }}
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText>
                                            <i class="fas fa-eye" style={passwordType!=='password'?{color:'#007bff'}:{ cursor: "pointer" }}  onClick={() => {
                                                if (passwordType == "password") {
                                                    setpasswordType("text")
                                                }
                                                else {
                                                    setpasswordType("password")
                                                }
                                            }}></i></InputGroupText>
                                    </InputGroupAddon>
                                    <FormFeedback>Password not match.</FormFeedback>
                                </InputGroup> 
                            </Col>


                            <Col lg="6">
                            <div style={{display:'flex',justifyContent:"left"}}>
                            {/* <Button squared theme="light"
                             style={{
                                color:"blue",cursor:"pointer",fontSize:"15px"}}
                                 onClick={()=>{ Loginnow()} }>
                                  Login?
                                </Button> */}
                                
                                 <a style={{color:"blue",cursor:"pointer",fontSize:"15px",fontWeight:"normal"}} onClick={Loginnow}
                            >Login?</a>
                            {/* <a style={{color:"#007bff",textDecoration:"underline",cursor:"pointer"}} href="/login">Login?</a> */}
                            </div>
                               
                            </Col>

                            <Col lg="6">
                                <div style={{display:'flex',justifyContent:"end"}}>
                                <Button 
                                // theme="success"
                                // outline pill 
                                onClick={()=>{
                                    Register()
                                }}>
                                   Sign up
                                </Button>
                                </div>
                            </Col>
                            
                        </Row>
                    </Form>
                </CardBody>

            </Card>
        </Col>

    </div>)
}

export default Login