import React, { Component, useCallback, useEffect, useState } from 'react'
import { render } from 'react-dom'
import '../../assets/default.css'
// import Slider from 'react-rangeslider'
import { Card, Col,Modal,Button, Form, FormGroup, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, ModalBody, ModalHeader, Row, Slider } from 'shards-react'
import { _http } from '../../interceptor'
import { notification } from '../../utils/notifications'
import Cookies from 'universal-cookie';
import Affiliatemodal from './Affiliatemodal'

const DiscountSlider = () => {
    const cookies = new Cookies();
    const [commission,setcommision] = useState(0);
    const [volume, setvolume] = useState(0)
     const [referralID,setreferralID] = useState('')
    const [page,setPage]=useState(1);
    const [showLimit,setShoWlimit]=useState(10);
    useEffect(() => {
        let uid=cookies.get('userId')
        let affiliateToken=cookies.get('apiToken')
        _http.post('/dashboard',{uid,affiliateToken,page,showLimit}).then(res=>{
            // const [referralID,setreferralID] = useState(res.data.affiliateData.referalId)
       setreferralID(res.data.affiliateData.referalId)
      
        }).catch(err => {
         
          console.log(err)
        })
      }, [])
    //    console.log(referralID)
    useEffect(()=>{
        if(localStorage.getItem('commission')){
            // console.log('first')
            let commission = localStorage.getItem('commission')
            setcommision(parseFloat(commission))
            setvolume(20-parseFloat(commission))
        }
        if(localStorage.getItem('referal')){
            setreferralID(localStorage.getItem('referal'))
        }
    },[])

    const handleOnChange = useCallback((value) => {
        setvolume(value[0])
    })
    useCallback(()=>{
        // console.log('hello')
    },[commission])
    const [referralModal,setreferralModal] = useState(false)
    //  const [newReferral,setnewReferral] = useState("njdfiu")
    // const handleRefferal = useCallback((value)=>{
    //     setnewReferral(value) 
    // },[])
    const userId = localStorage.getItem('userId')
    // function updateRefferal(){
    //     let uid=cookies.get('userId')
    //     let affiliateToken=cookies.get('apiToken')
    //     _http.patch('/referalIdUpdate',{uid,affiliateToken,newReferral}).then(res=>{
    //         notification(res.data.Message,'success','Refferal Id')
    //         setreferralID(newReferral)
    //         localStorage.setItem('referal',newReferral)
    //         setreferralModal(false)
            // 
        // _http.get('/dashboard',{
        // // _http.post('/dashboardservices/update-user',{
        //     "userId": localStorage.getItem('userId'),
        //     "referal": newReferral
        // }).then(res=>{
            // console.log("discountslider")
            //   console.log(res.data.Message)
            // if(res.data.status=="success"){
            //     notification(res.data.Message,'success','Refferal Id')
            //     setreferralID(newReferral)
            //     localStorage.setItem('referal',newReferral)
            //     setreferralModal(false)
            // }

    //     }).catch(error=>{
    //         notification(error.data.Message,'danger','Error')
    //         // if(error.response){
    //         //     notification(error.response.data.message,'danger','Error')
    //         // }
    //         console.log(error)
    //     })
    // }
    function CopyCustomerText() {
        var copyText = document.getElementById('affliateLink')
        navigator.clipboard.writeText(copyText.value)
        notification("Affiliate link copied",'success','')

    }
    return (<>
        <Row className="border-bottom py-2 bg-light">
            <Col sm="6" className="p-2">
                You get  {parseFloat(commission?commission:'20') - volume}% commission
            </Col>
            {/* <Col sm="6">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    You give {volume}% discount
                </div>

            </Col> */}
            {/* <Col sm="12" className="d-flex mb-2 mb-sm-0">
                <div style={{ width: "100%" }}>
                    <Slider

                        theme="success"
                        className="my-4"
                        connect={[true, false]}
                        start={[volume]}
                        range={{ min: 0, max: 20 }}
                        tooltips
                        step={.5}
                        onChange={handleOnChange}
                    />
                </div>
            </Col> */}
            {/* <Col sm="2" className="d-flex mb-2 mb-sm-0">
                <div style={{ display: "flex", alignItems: "center" }}>
                    {volume}
                </div>
            </Col> */}
            <Col sm="12" lg="12" md="12" className="mb-2 mb-sm-0">
                <Form >
                    <FormGroup>

                        <InputGroup className="mb-3">
                            <FormInput
                                id="affliateLink"
                                disabled
                                placeholder="1234 Main St"
                                value={"https://api.reviewbit.app/api/affiliatelink?affiliateid="+referralID}
                                onChange={() => { }}
                            />
                            <InputGroupAddon type="prepend" onClick={()=>{
                                setreferralModal(true)
                                console.log('click')}} className="cursor">
                                <InputGroupText >
                                    <i class="fas fa-edit"></i></InputGroupText>
                            </InputGroupAddon>
                            <InputGroupAddon type="prepend" onClick={()=>{
                                CopyCustomerText()
                            }} className="cursor">
                                <InputGroupText><i class="far fa-copy"></i></InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>

                    </FormGroup>
                </Form>
            </Col>
            <Col>
                <span>
                    Your affiliate commission payout is {20 - volume}% of whatever the store registered through the above link spends on a paid plan for Reviewbit
                </span>
            </Col>
           { referralModal && <Affiliatemodal referralID={referralID}setreferralID={setreferralID} setreferralModal={setreferralModal} />}
            {/* <Modal open={referralModal} toggle={()=>{setreferralModal(false)}}>
            <ModalHeader>Create a new referal ID</ModalHeader>
                <ModalBody>
                    <FormInput
                        //  placeholder={referralID}
                        //  value={referralID}
                       value={newReferral}
                        onChange={($event) => {
                            handleRefferal($event.target.value)
                        }}
                    />
                   <br/>
                   <div className="flex-end">
                        <Button className="m-2" onClick={()=>{
                            updateRefferal()
                            // console.log(userId)
                        }}>
                            Update ID
                        </Button>
                        <Button className="m-2" theme="danger" onClick={()=>{
                            setreferralModal(false)
                        }}>
                            Cancel
                        </Button>
                   </div>     
                </ModalBody>
            </Modal> */}
        </Row>
    </>)
}

export default DiscountSlider