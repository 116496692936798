import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import routes from "./routes";
import withTracker from "./withTracker";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";
import { _http } from "./interceptor";
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import "./assets/responsive.css"



export default () => (
  _http.interceptors.request.use(function (config) {
        config.headers["Authorization"] = localStorage.getItem('Authorization')
        return config;
  }),
  <Router basename={process.env.REACT_APP_BASENAME || ""}>
    <div>
    <ReactNotification />
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={withTracker(props => {
              // console.log(props)
              return (
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            })}
          />
        );
      })}
    </div>
  </Router>
);
