import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import {AppProvider} from "@shopify/polaris";
// import en from '@shopify/polaris/locales/en.json';

// import "@shopify/polaris/build/esm/styles.css";

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<AppProvider>
//     <App /></AppProvider>, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
