import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, CardHeader,Alert,Progress} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import 'react-rangeslider/lib/index.css'
import DiscountSlider from "./Dashboard components/Discount_slider";
import RefferedStores from "./Dashboard components/RefferedStores";
import { _http } from "../interceptor";
import Cookies from 'universal-cookie';
import Loader from "react-loader-spinner";
const Dashboard = () => {
  document.title="Reviewbit | Affiliate Dashboard"
  const cookies = new Cookies();
  const [mobileView, setmobileView] = useState(false)
  const [page,setPage]=useState(1);
  const [showLimit,setShoWlimit]=useState(10);
   const [BigNumberLoading, setBigNumberLoading] = useState(false)
  const [Refferals, setRefferals] = useState({
    label: "Referrals",
    value: "0",
   increase: true,
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "6", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(0, 184, 216, 0.1)",
        borderColor: "rgb(0, 184, 216)",
        data: []
      }
    ]
  })
  const [TotalEarnings,setTotalEarnings] = useState({
    label: "Total Earnings",
    value: "$ 0",
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,180,0,0.1)",
        borderColor: "rgb(255,180,0)",
        data: []
      }
    ]
  })
  const[totPaid,settotPaid] = useState({
    label: "Total paid",
    value: "0",
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgb(255,65,105)",
        data: []
      }
    ]
  })

  const[totalviews,settotalviews] = useState({
    label: "Total views",
    value: "0",
    chartLabels: [null, null, null, null, null, null, null],
    attrs: { md: "4", sm: "6" },
    datasets: [
      {
        label: "Today",
        fill: "start",
        borderWidth: 1.5,
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgb(255,65,105)",
        data: []
      }
    ]
  })
 
  useEffect(()=>{
    let uid=cookies.get('userId')
    let affiliateToken=cookies.get('apiToken')
    setBigNumberLoading(true)
    _http.post('/dashboard',{uid,affiliateToken,page,showLimit}).then(res=>{
      setBigNumberLoading(false)
      settotalviews({
        label: "Visitors",
        value: res.data.affiliateData.views,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            data: []
          }
        ]
      })
       setRefferals({
        label: "Referrals",
        value:res.data.affiliateData.totalReferals,
        increase: true,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(0, 184, 216, 0.1)",
            borderColor: "rgb(0, 184, 216)",
            data: []
          }
        ]
      })
      setTotalEarnings({
        label: "Total Earnings",
        value: "$" + res.data.affiliateData.totalEarnings,
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,180,0,0.1)",
            borderColor: "rgb(255,180,0)",
            data: []
          }
        ]
      })
      settotPaid({
        label: "Total paid",
        value: '$'+ res.data.affiliateData.totalPaid,
        chartLabels: [null, null, null, null, null, null, null],
        attrs: { md: "4", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(255,65,105,0.1)",
            borderColor: "rgb(255,65,105)",
            data: []
          }
        ]
      })
  
    }).catch(err=>{
      // console.log(err)
    })
  },[])
  const BasicProgress=()=> {
    return <Progress theme="primary" value={100} />;
  }
  return ( <>
  <Container fluid className="main-content-container px-4">
  {/* <div className="main-content-container px-4"> */}
  <Card className={mobileView ? "polariscard-bannerMobile" : "polariscard-banner"}>

    <CardHeader className="Polaris-DisplayTextNomargin">Referral Program</CardHeader>
    <p className="m-1">This is where you can share Reviewbit with other friendly stores and earn some money in the process. You'll get 20% commission for life on whatever the stores referred by you will pay.</p>
  </Card>
  <Card className={mobileView ? "polariscard1mobile" : "polariscard1"}>
    <Row>
      <Col lg={{ size: 6 }} md="6" sm="12" className="mb-4">
        <Card className="polariscard" small>
          <CardHeader className="Polaris-DisplayText">
            {"Affiliate link"}
          </CardHeader>
          <CardBody className="pt-0">
            <DiscountSlider />
          </CardBody>
        </Card>

      </Col>
      <Col lg={{ size: 6, }} md="6" sm="12" className="mb-4"></Col>

      <Col lg="3" className="mb-4 ">
        <Card className={'polariscard'} small>
          <CardHeader className="Polaris-DisplayText">{totalviews.label}</CardHeader>
          {BigNumberLoading && <div className="ml-4 ">
            <Loader color="gray" type="ThreeDots" height={55} width={60} /><br /></div>}
          {!BigNumberLoading && <CardBody className="Polaris-DisplayText--sizeLarge">{totalviews.value}</CardBody>}

        </Card>
      </Col>
      <Col lg="3" className="mb-4">
        <Card className={'polariscard'} small>
          <CardHeader className="Polaris-DisplayText">{Refferals.label}</CardHeader>
          {BigNumberLoading && <div className="ml-4 ">
            <Loader color="gray" type="ThreeDots" height={55} width={60} /><br /></div>}
          {!BigNumberLoading && <CardBody className="Polaris-DisplayText--sizeLarge">{Refferals.value}</CardBody>}

        </Card>
      </Col>
      <Col lg="3" className="mb-4">
        <Card className={'polariscard'} small>
          <CardHeader className="Polaris-DisplayText">{totPaid.label}</CardHeader>
          {BigNumberLoading && <div className="ml-4 ">
            <Loader color="gray" type="ThreeDots" height={55} width={60} /><br /></div>}
          {!BigNumberLoading && <CardBody className="Polaris-DisplayText--sizeLarge">{totPaid.value}</CardBody>}
        </Card>
      </Col>
      <Col lg="3" className="mb-4">
        <Card className={'polariscard'} small>
          <CardHeader className="Polaris-DisplayText">{TotalEarnings.label}</CardHeader>
          {BigNumberLoading && <div className="ml-4 ">
            <Loader color="gray" type="ThreeDots" height={55} width={60} /><br /></div>}
          {!BigNumberLoading && <CardBody className="Polaris-DisplayText--sizeLarge">{TotalEarnings.value}</CardBody>}
        </Card>
      </Col>
      <br />
    </Row>
    <CardHeader className="Polaris-DisplayTextNomargin mb-2">
      {"Referred stores"}
    </CardHeader>
    <Row>
      <Col lg={{ size: 12, offset: 0 }} sm="12">
        <Card className={"polariscard"}>
          <RefferedStores />
        </Card>
      </Col>
    </Row>
  </Card>

</Container>
</>)
}

export default Dashboard;

