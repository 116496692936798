import axios from "axios";

const _http = axios.create({
  baseURL: 'https://api.reviewbit.app/api'
  // headers:{
  //   "Authorization":localStorage.getItem('Authorization')
  // }
  
});
// Export your Axios instance to use within your app
export { _http };
