import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  Button, Col, Container, FormInput, InputGroup, InputGroupAddon, InputGroupText, Nav, Navbar, Row, Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";
import { useHistory } from "react-router";
import { useWindowSize } from "@react-hook/window-size";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { notification } from "../../../utils/notifications";
import { _http } from "../../../interceptor";
import Loader from "react-loader-spinner";
import Cookies from 'universal-cookie';
const MainNavbar = ({ layout, stickyTop }) => {
  const [mobileView, setmobileView] = useState(false)
  const [width] = useWindowSize()
  var Router = useHistory();
  const cookies = new Cookies();
  const [openChangeProfile, setOpen] = useState(false);
  const [openChangePassword, setopenChangePassword] = useState(false)
  const onOpenModalPasswordModal = () => setopenChangePassword(true)
  const onCloseModalPassword = () => setopenChangePassword(false)
  const [loader, setloader] = useState(false)
  const onCloseModal = () => setOpen(false);
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('')
  const [oldpassword, setoldpassword] = useState('')
  const [newpassword, setnewpassword] = useState('')
  const [confirmpassword, setconfirmpassword] = useState('')
  const [passwordFormSubmit, setpasswordFormSubmit] = useState(false)
  const [passwordType, setpasswordType] = useState("password")


  const onOpenModal = useCallback(() => {
    let uid = cookies.get('userId')
    let affiliateToken = cookies.get('apiToken')
    setloader(true)
    _http.post('/dashboard', { uid, affiliateToken }).then(res => {
      // console.log(res.data)
      if (res.data) {
        setOpen(true)
        setfirstname(res.data.affiliateData.first_name)
        setlastname(res.data.affiliateData.last_name)

        setloader(false)
      }
    }).catch(err => {
      setloader(false)
      notification('Some thing went wrong!', 'danger', '')
      // console.log(err)
    })
  }, [loader, firstname, lastname])


  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );

  const handleOldpassword = useCallback((value) => {
    setoldpassword(value)
  })
  const handleNewpassword = useCallback((value) => {
    setnewpassword(value)
  })
  const handleFirstname = useCallback((value) => {
    setfirstname(value)
  })
  const handleLastname = useCallback((value) => {
    setlastname(value)
  })
  const handleConfirmpassword = useCallback((value) => {
    setconfirmpassword(value)
  })

  useEffect(() => {
    if (width < 800) {
      setmobileView(true)
    }
    else {
      setmobileView(false)
    }
  }, [width])

  const ChangePassword = useCallback(() => {
    let uid = cookies.get('userId')
    setpasswordFormSubmit(true)
    if (oldpassword !== '' && newpassword !== '' && confirmpassword !== '') {
      if (oldpassword !== newpassword) {
        _http.patch('/passwordUpdate', {
          "uid": uid,
          "oldPassword": oldpassword,
          "newPassword": newpassword
        }).then(res => {
          if (res.data.Status == "200") {
            notification(res.data.Message, 'success', 'Success')
            setoldpassword('');
            setnewpassword('');
            setconfirmpassword('');
            setopenChangePassword(false)
          }
          else {
            notification(res.data.Message, 'danger', 'Error')
            setopenChangePassword(false)
          }
          // console.log(res)
          //  if(res)
          // notification(res.data.Message, res.data.Status, 'Settings')
          // notification(res.data.Message,'success','Success')
          //   setopenChangePassword(false)

          // if (res.data) {
          //   notification(res.data.Message, res.data.Status, 'Settings')
          //   setopenChangePassword(false)
          // }
        }).catch((error) => {
          // notification(error.data.Message, 'danger', 'Settings')
          // if (error.response.data) {
          //   notification(error.response.data.Message, 'danger', 'Settings')
          //   console.log(error.response);
          // }

        })
      }
      else {
        notification('New paswword cant be same as Old password', 'warning', 'Settings')
      }
      if (newpassword !== confirmpassword) {
        notification('password doesnt match!', 'warning', 'Settings')
      }
    }
  }, [oldpassword, newpassword, confirmpassword])

  const ChangeProfile = useCallback(() => {
    let uid = cookies.get('userId')
    if (uid) {
      _http.patch('/passwordUpdate', {
        "uid": uid,
        "firstName": firstname,
        "lastName": lastname
      }).then(res => {
        if (res.data.Status == "200") {
          notification(res.data.Message, 'success', 'Success')
          setOpen(false)
        }
        else {
          notification(res.data.Message, 'danger', 'Error')
          setOpen(false)
        }
        // console.log(res.data)

        // if (res.data) {
        //   notification(res.data.Message, res.data.status, 'Settings')
        //   setOpen(false)
        // }
      }).catch(err => {
        // console.log(err)
      })
    }
  }, [firstname, lastname])

  return (
    <div className={classes}>

      <Container className="p-0">
        <Navbar type="light" className="align-items-center flex-md-nowrap p-0">
          <h4>Affiliate Dashboard</h4>
          {!mobileView ? <Nav></Nav> : ""}
          <div>

          </div>
          <Nav navbar className="border-left flex-row">
            <>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '20px' }} onClick={() => {
                onOpenModal()
              }}>
                <div> <i className="far fa-user text-success"> </i> Change Profile</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '20px' }} onClick={() => {
                onOpenModalPasswordModal()
              }}>
                <div> <i className="fas fa-key text-info"></i> Change Password</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', paddingLeft: '20px' }} onClick={() => {
                localStorage.removeItem('Authorization')
                localStorage.removeItem('referal')
                localStorage.removeItem('userId')
                localStorage.removeItem('commission')
                cookies.remove('userId');
                cookies.remove('useremail');
                cookies.remove('apiToken');

                Router.push('/')
              }}>
                <div>
                  <i className="material-icons text-danger">&#xE879;</i>
                </div>
                <div style={{ paddingLeft: '5px', fontWeight: '400', color: 'black', marginRight: '10px' }}> Logout</div>
              </div>
            </>
          </Nav>
        </Navbar>
      </Container>
      <Modal open={loader} showCloseIcon={false} classNames={{ modal: "loadermodal" }} center>
        <Row>
          <Col md="12" className="form-group" >
            <Loader type="ThreeDots" color="white" height={55} width={60} />
          </Col>
        </Row>
      </Modal>
      <Modal open={openChangeProfile} classNames={{ modal: "custommodal" }} onClose={onCloseModal} center>
        <br />
        <br />
        <>
          <Row form>
            <Col md="12" className="form-group">
              <FormInput
                id="feEmailAddress"
                value={firstname}
                placeholder="First name"
                required
                onChange={
                  ($event) => {
                    handleFirstname($event.target.value)
                  }
                }
              />
            </Col>
            <Col md="12" className="form-group">
              <FormInput
                id="feEmailAddress"
                value={lastname}
                placeholder="Last name"
                required
                onChange={
                  ($event) => {
                    handleLastname($event.target.value)
                  }
                }
              />
            </Col>
            <Col lg="12">
              <div style={{ display: 'flex', justifyContent: "center" }}>
                <Button outline pill onClick={() => {
                  ChangeProfile()
                }

                }>
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </Modal>
      <Modal open={openChangePassword} classNames={{ modal: "custommodal" }} onClose={onCloseModalPassword} center>
        <br />
        <br />
        <>
          <Row form>
            <Col md="12" className="form-group">
              <FormInput
                id="Old Password"
                value={oldpassword}
                placeholder="Old Password"
                required
                autoComplete="off"
                // invalid={(passwordFormSubmit && oldpassword === '')}
                onChange={
                  ($event) => {
                    handleOldpassword($event.target.value)
                  }
                }
              />
            </Col>
            <Col md="12" className="form-group">
              <InputGroup>
                <FormInput
                  id="New password"
                  value={newpassword}
                  placeholder="New password"
                  required
                  autoComplete="off"
                  type={passwordType}
                  // invalid={(passwordFormSubmit && newpassword === '')}
                  onChange={
                    ($event) => {
                      handleNewpassword($event.target.value)
                    }
                  }
                />
                <InputGroupAddon type="append">
                  <InputGroupText>
                    <i class="fas fa-eye" style={passwordType !== 'password' ? { color: '#007bff' } : { cursor: "pointer" }} onClick={() => {
                      if (passwordType === "password") {
                        setpasswordType("text")
                      }
                      else {
                        setpasswordType("password")
                      }
                    }}></i></InputGroupText>
                </InputGroupAddon>
              </InputGroup>

            </Col>
            <Col md="12" className="form-group">
              <FormInput
                id="Confirm password"
                value={confirmpassword}
                placeholder="Confirm password"
                required
                autoComplete="off"
                // invalid={(passwordFormSubmit && confirmpassword === '')}
                onChange={
                  ($event) => {
                    handleConfirmpassword($event.target.value)
                  }
                }
              />
            </Col>
            <Col lg="12">
              <div style={{ display: 'flex', justifyContent: "center" }}>
                <Button outline pill onClick={() => {
                  ChangePassword()
                }

                }>
                  Update
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </Modal>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
