import React, { useCallback, useState } from 'react'
import { useHistory, useRouteMatch,Router, Route } from 'react-router';
import Cookies from 'universal-cookie';
import {
    Row,
    Col,
    Form,
    FormInput,
    Button,
    Card,
    CardBody,
    CardHeader,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react";
import { _http } from '../interceptor';
import { notification } from '../utils/notifications';

const Login = () => {
    document.title="Reviewbit | Affiliate Login"
    var Router = useHistory();
    const cookies = new Cookies();
    const [username, setusername] = useState('');
    const [password, setpassword] = useState('')
    const handleUsername = useCallback((value) => {
        setusername(value)
    })
    const handlePassword = useCallback((value) => {
        setpassword(value)
    })
    const [passwordType, setpasswordType] = useState("password")
    const Registernow=()=>{
        Router.push('/signup')
        // Router.push('/dashboard')
    }
    function login(){
       if(username=="" || password=="" )
       {
        notification("Enter username & password",'danger','Error')
       }
       else{
        _http.post('/login',{
        // _http.post('/dashboardservices/login',{
            "email":username,
            "password":password
        }).then(res=>{
                  if(res.data.Status=="200"){
                cookies.set('userId',res.data.userDetails._id);
                cookies.set('apiToken',res.data.userDetails.apiToken);
                cookies.set('useremail',res.data.userDetails.email);
                notification(res.data.Message,'success','Login')
                Router.push('/dashboard')   
            }
            // else{
            //     notification(res.data.Message,'danger','Error') 
            // }
                
        
            // console.log(res)
        }).catch(err=>{
            if(err.response){
                notification(err.response.data.Message,'danger','Error')
            }
      
        })
    }
    }
    return (<div style={{ marginTop: "10%" }}>
        <Col sm="6" lg={{ size: 3, offset: 4 }}>
            <Card >
                <CardHeader className="border-bottom">
                    <div style={{display:"flex",justifyContent:"center"}}>
                      <img src="reviewbit-logo.png" style={{width:"100px"}}></img>
                    </div>
                    <h6 style={{ display: 'flex', justifyContent: "center",margin:"0",fontSize: "20px",
                    fontWeight: "bold",textAlign:"center"}} >Welcome to Reviewbit</h6>
                </CardHeader>
                <CardBody>
                    <Form>
                        <Row form>
                            <Col md="12" className="form-group">
                                {/* <label htmlFor="feEmailAddress">Username</label> */}
                                <FormInput
                                    id="feEmailAddress"
                                    // valid={(username!='')?true:false}
                                    value={username}
                                    placeholder="username@email.com"
                                    required
                                    autoComplete="off"
                                    // valid
                                    onChange={
                                        ($event) => {
                                            handleUsername($event.target.value)
                                        }
                                    }
                                />
                                {/* <FormFeedback valid>The first name looks good!</FormFeedback> */}
                            </Col>
                            <Col md="12" className="form-group">
                                {/* <label htmlFor="fePassword">Password</label> */}
                                <InputGroup>

                                    <FormInput
                                        id="fePassword"
                                        // valid={(password!='')?true:false}
                                        value={password}
                                        placeholder="password"
                                        required
                                        type={passwordType}
                                        autoComplete="off"
                                        // valid
                                        onChange={($event) => {
                                            // console.log($event.target.value)
                                            handlePassword($event.target.value)
                                        }}
                                    />
                                    <InputGroupAddon type="append">
                                        <InputGroupText>
                                            <i class="fas fa-eye" style={passwordType!=='password'?{color:'#007bff'}:{ cursor: "pointer" }} onClick={() => {
                                                if (passwordType == "password") {
                                                    setpasswordType("text")
                                                }
                                                else {
                                                    setpasswordType("password")
                                                }
                                            }}></i></InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                                {/* <FormFeedback valid>The last name looks good!</FormFeedback> */}
                            </Col>
                            <Col lg="6">
                            <div style={{display:'flex',justifyContent:"left"}}>
                            {/* <Button squared theme="light"
                             style={{
                                color:"blue",cursor:"pointer",fontSize:"15px"}}
                                 onClick={()=>{ Registernow()}}>
                                   Register Now?
                                </Button> */}
                            <a style={{color:"blue",cursor:"pointer",fontSize:"14px",fontWeight:"normal"}} onClick={Registernow}
                            >Register Now?</a>
                            </div>
                               
                            </Col>
                            {/* <Col lg="6">
                             <Button squared theme="light"
                             style={{
                                color:"blue",cursor:"pointer",fontSize:"15px"}}
                                 onClick={()=>{ Registernow()} }>
                                   Register Now?
                                </Button> */}
                                 {/* <a href="/signup" 
                                 style={{color:"#007bff",
                                 textDecoration:"underline",cursor:"pointer",fontSize:"small"}}>Register Now?</a> */}
                                {/* <a  onClick='Registernow()'>Register Now?</a> */}
                                 {/* </Col> */}
                            <Col lg="6">
                                <div style={{display:'flex',justifyContent:"end"}}>
                                <Button onClick={()=>{
                                    // console.log('hello')
                                    login()
                                }
                                    
                                }>
                                    Sign in
                                </Button>
                                </div>
                               
                            </Col>
                            {/* <Col lg="12">
                            <div style={{display:'flex',justifyContent:"center"}}>
                            <a style={{color:"#007bff",textDecoration:"underline",cursor:"pointer"}}>Register Now?</a>
                            </div>
                               
                            </Col> */}
                        </Row>
                    </Form>
                </CardBody>

            </Card>
        </Col>

    </div>)
}

export default Login