import React, { Component, useCallback, useEffect, useState } from 'react'
import { Card, Col,Modal,Button, Form, FormGroup, FormInput, FormSelect, InputGroup, InputGroupAddon, 
    InputGroupText, ModalBody, ModalHeader, Row, Slider } from 'shards-react'
    import { _http } from '../../interceptor'
import { notification } from '../../utils/notifications'
import Cookies from 'universal-cookie';

const Affiliatemodal=({
    referralID={referralID},
    setreferralID={},
     setreferralModal={},
    })=>{
        const cookies = new Cookies();
        const [newReferral,setnewReferral] = useState(referralID)
    const handleRefferal = useCallback((value)=>{
        
        setnewReferral(value) 
    },[])
  const updateRefferal=()=>{
        let uid=cookies.get('userId')
        let affiliateToken=cookies.get('apiToken')
        if(newReferral==="")
        {
            notification("Enter referal ID",'danger','Error') 
        }
        else{
        _http.patch('/referalIdUpdate',{uid,affiliateToken,newReferral}).then(res=>{
            notification(res.data.Message,'success','Refferal Id')
            setreferralID(newReferral)
            localStorage.setItem('referal',newReferral)
            setreferralModal(false)
        }).catch(error=>{
            notification(error.data.Message,'danger','Error')
           
        })
    }
    }
    return(<div>
        <Modal open={setreferralModal} toggle={()=>{setreferralModal(false)}}>
            <ModalHeader>Edit referral link</ModalHeader>
                <ModalBody>
                    New referral code
                    <FormInput
                        //  placeholder={referralID}
                        //  value={referralID}
                        value={newReferral}
                        onChange={($event) => {
                            handleRefferal($event.target.value)
                        }}
                    />
                    {/* <span>{referralID==""?"Enter referralID":""}</span> */}
                   <br/>
                   <div className="flex-end">
                        <Button className="m-2" onClick={updateRefferal}>
                            Update ID
                        </Button>
                        <Button className="m-2" theme="danger" onClick={()=>{
                            setreferralModal(false)
                        }}>
                            Cancel
                        </Button>
                   </div>     
                </ModalBody>
            </Modal>
    </div>)
}
export default Affiliatemodal